export const featureStatusEnum = {
  ACTIVE: 0,
  INACTIVE: 1,
}

export const featureStatus = {
  [featureStatusEnum.ACTIVE]: 'Enabled',
  [featureStatusEnum.INACTIVE]: 'Disabled',
}

export const featureTitleEnum = {
  VACCINE: 'Vaccine',
  QUICK_LINKS: 'Quick Links',
  SECTION: 'Section',
  DRAWER: 'Drawer',
  MENU_BOTTOM: 'Menu on Bottom Navigation'
}

export const featurePreviewImageName = {
  [featureTitleEnum.VACCINE]: 'preview-section.png',
  [featureTitleEnum.QUICK_LINKS]: 'preview-quick-links.png',
  [featureTitleEnum.SECTION]: 'preview-section.png',
  [featureTitleEnum.DRAWER]: 'preview-drawer.png',
  [featureTitleEnum.MENU_BOTTOM]: 'preview-menu-on-bottom-navigation.png'
}
