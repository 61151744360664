<template>
  <v-alert
    v-model="show"
    transition="fade-transition"
    dense
    class="snackbar-message-error custom-alert body-regular px-2 my-2"
    t-data="error-alert"
  >
    <div class="d-flex flex-row align-center mt-0">
      <v-img
        src="/images/snackbar-error.png"
        aspect-ratio="1"
        snackbar-image
        class="mt-0 mr-2 snackbar-image"
      ></v-img>
      <p class="mb-0">
        {{ msg }}
      </p>
    </div>
  </v-alert>
</template>

<script>
  export default {
    name: "BannerError",
    props: {
      show: {type: Boolean},
      msg: {type: String}
    },
  }
</script>

<style lang="scss" scoped>
.custom-alert {
  background-color: var(--danger-200-color) !important;
}
</style>
