<template>
  <v-row>
    <v-col
      class="no-top-bottom-space information-label body-regular body-bold primaryTextColor--text"
      cols="8"
      t-data="item-name"
    >
      <p>{{ name }}</p>
    </v-col>
    <v-col
      class="no-top-bottom-space information-text body-regular body-bold primaryTextColor--text text-right d-flex justify-end"
      cols="4"
      t-data="item-status"
    >
      <span v-if="statusValue === 99">Always enabled</span>
      <template v-else>
        <v-switch
          t-data="status-switch"
          color="primary200Color"
          v-model="statusValue"
          :false-value="1"
          :true-value="0"
          class="mb-0 pb-0 mt-0 custom-switch feature-switch"
          @change="handleSwitchStatusChange"
        ></v-switch>
        <span style='width: 70px'>{{ statusName(statusValue) }}</span>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import {featureStatus} from '@/constants/feature-setup'

export default {
  name: 'FeatureItem',
  props: {
    name: {type: String, required: true},
    status: {type: Number, required: false, default: 99}
  },
  data() {
    return {
      currentStatus: this.status,
    }
  },
  computed: {
    statusValue: {
      get() { return this.currentStatus },
      set(value) {
        this.currentStatus = value;
      }
    }
  },
  methods: {
    statusName(status) {
      return featureStatus[status]
    },
    handleSwitchStatusChange(status) {
      this.$emit('click', status)
    },
  }
}
</script>

<style lang="scss">
.feature-switch > .v-input__control > .v-messages.theme--light {
  display: none;
}
.custom-switch.feature-switch > .v-input__control > .v-input__slot {
  justify-content: right;
}

.custom-switch.feature-switch
  > .v-input__control
  > .v-input__slot
  > .v-label {
  flex: unset;
  padding-left: 16px;
  color: var(--primary-text-color);
}
</style>
