<template>
  <v-app class="px-10 detail-container">
    <v-popup-loading :isLoading="isLoading" />
    <v-snackbar
      v-model="isError"
      width="500"
      color="danger200Color"
      top
      centered
    >
      <div class="d-flex flex-row align-center mt-0">
        <v-img
          src="/images/snackbar-error.png"
          aspect-ratio="1"
          snackbar-image
          class="mt-0 mr-2 snackbar-image"
        ></v-img>
        <p class="snackbar-message-error sub-body-regular mb-0">
          Feature Setup display status have been updated Unsuccessfully.
        </p>
      </div>
    </v-snackbar>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>

    <!-- Breadcrumbs -->
    <v-row>
      <v-col class="pb-0">
        <v-breadcrumbs
          :items="linkroute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            Edit Feature
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 button-bold"
            color="whiteColor"
            t-data="cancel-btn"
            @click="showCancelPopup"
          >
            Cancel
          </v-btn>
          <v-btn
            class="white-text-button button-bold"
            t-data="save-btn"
            @click="save"
            color="primary600Color"
          >
            <v-icon left light>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8" class="my-0 py-0">
        <v-card class="mt-5" v-if="featureSetupDetail.org.codename !== ''">
          <v-card-title class="mt-0 pt-0 px-0 pb-3">
            <h6 t-data="card-title" class="mb-0 card-title">
              Feature Information
            </h6>
          </v-card-title>
          <v-row class="mt-0"><v-divider></v-divider></v-row>
          <v-card-text class="mt-0">
            <h6
              t-data="hospital-title"
              class="mb-0 py-4 card-title pb-4 headerTextColor--text"
            >
              {{ featureSetupDetail.org.name_th }} ({{
                featureSetupDetail.org.codename.toUpperCase()
              }})
            </h6>
            <v-row class="mt-0"><v-divider></v-divider></v-row>

            <template
              v-for="(feature, indexFeature) in featureSetupDetail.features"
            >
              <div
                t-data="feature-container"
                :key="`feature-${indexFeature}`"
                @mouseover="setPreviewImage(feature.title)"
                @mouseout="setDefaultPreviewImage"
                class="mt-0"
              >
                <h6
                  t-data="feature-title"
                  class="mb-0 pt-4 card-title pb-4 headerTextColor--text"
                >
                  {{ feature.title }}
                </h6>
                <span class="body-regular" t-data="feature-description">{{
                  feature.description
                }}</span>
                <banner-error
                  v-if="isQuicKLinks(feature.title)"
                  :show="isErrorQuickLinks"
                  msg="Please select all 4 and not less than 4."
                ></banner-error>
                <v-row class="mb-3">
                  <v-col
                    t-data="content-container"
                    class="no-top-bottom-space information-label body-regular"
                    cols="12"
                  >
                    <feature-item
                      v-for="(content, indexContent) in feature.contents"
                      :key="`content-${indexContent}`"
                      :name="content.name"
                      :status="featureItemStatus(feature.title, content.status)"
                      @click="
                        e =>
                          handleSwitch(
                            e,
                            feature.title,
                            indexFeature,
                            indexContent
                          )
                      "
                    ></feature-item>
                  </v-col>
                </v-row>
                <v-row
                  t-data="bottom-divider"
                  class="mt-0"
                  v-if="featureSetupDetail.features.length > indexFeature + 1"
                  ><v-divider></v-divider
                ></v-row>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="mt-0 pt-0">
        <div class="sticky-container">
          <v-card class="mt-5 mb-4">
            <v-card-title class="mt-0 pt-0 px-0 pb-3">
              <h6 t-data="card-title" class="mb-0 card-title">
                Screen Preview
              </h6>
            </v-card-title>
            <v-row class="mt-0"><v-divider></v-divider></v-row>
            <v-card-text class="mt-0" t-data="screen-preview-container">
              <v-spacer></v-spacer>
              <v-img
                max-height="620"
                max-width="331"
                :src="`/images/screen-preview/${previewImageName}`"
              ></v-img>
              <v-spacer></v-spacer>
            </v-card-text>
          </v-card>
          <v-row
            class="align-center"
            style="margin-left: 1px; margin-right: 0;"
          >
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 button-bold"
              color="whiteColor"
              t-data="cancel-btn"
              @click="showCancelPopup"
            >
              Cancel
            </v-btn>
            <v-btn
              class="white-text-button button-bold"
              t-data="save-btn"
              @click="save"
              color="primary600Color"
            >
              <v-icon left light>
                mdi-content-save-outline
              </v-icon>
              Save
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="d-flex align-start mt-0 mb-3"
      t-data="footer-row"
      style="margin-left: 1px; margin-right: 0;"
    >
      <created-detail
        :data-object="featureSetupDetail"
        :show-create="false"
      />
    </v-row>
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {featurePreviewImageName, featureStatusEnum, featureTitleEnum} from '@/constants/feature-setup'
import FeatureItem from '@/components/feature-setup/FeatureItem'
import PopupLoading from '@/components/PopupLoading'
import BannerError from '@/components/BannerError'
import CreatedDetail from '@/components/CreatedDetail'
import AlertConfirm from '@/components/AlertConfirm'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-popup-loading': PopupLoading,
    BannerError,
    FeatureItem,
    CreatedDetail
  },
  data() {
    return {
      linkroute: [
        {
          text: 'App Config',
          disabled: false,
          href: '/'
        },
        {
          text: 'Feature Setup',
          disabled: false,
          href: '/feature-setups'
        },
        {
          text: 'Edit Feature',
          disabled: true,
          href: '/'
        }
      ],
      isError: false,
      isCancel: false,
      isErrorQuickLinks: false,
      previewImageName: 'preview-main.png'
    }
  },
  beforeMount() {
    this.resetState()
  },
  async mounted() {
    await this.fetchDetail(this.$route.params.codename)
  },
  computed: {
    ...mapState('featureSetup', ['featureSetupDetail', 'isLoading'])
  },
  methods: {
    setPreviewImage(index) {
      if (Object.keys(featurePreviewImageName).includes(index)) {
        this.previewImageName = featurePreviewImageName[index]
      } else {
        this.setDefaultPreviewImage()
      }
    },
    setDefaultPreviewImage() {
      this.previewImageName = 'preview-main.png'
    },
    isQuicKLinks(title) {
      return title === featureTitleEnum.QUICK_LINKS
    },
    featureItemStatus(title, status) {
      if (title === featureTitleEnum.MENU_BOTTOM) {
        return undefined
      } else {
        return status
      }
    },
    validateQuickLink() {
      const LIMIT = 4
      const quickLinkFeatures = this.featureSetupDetail.features.filter(f =>
        this.isQuicKLinks(f.title)
      )
      if (quickLinkFeatures.length > 0) {
        const count = quickLinkFeatures[0].contents.filter(
          c => c.status === featureStatusEnum.ACTIVE
        ).length
        if (count >= LIMIT) {
          return true
        } else {
          this.isError = true
          this.isErrorQuickLinks = true
          return false
        }
      } else {
        // Dont have quick links
        return true
      }
    },
    handleSwitch(status, title, indexFeature, indexContent) {
      if (this.isQuicKLinks(title)) this.isErrorQuickLinks = false
      this.featureSetupDetail.features[indexFeature].contents[
        indexContent
      ].status = status
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.resetState()
      this.$router.push({name: 'feature-setup-list'})
    },
    async save() {
      if (this.validateQuickLink()) {
        const isSuccess = await this.update(this.$route.params.codename)
        if (isSuccess) {
          this.resetState()
          this.$router.push({
            name: 'feature-setup-list',
            query: {isCreateSuccess: true}
          })
        }
      }
    },
    ...mapActions('featureSetup', ['fetchDetail', 'resetState', 'update'])
  }
}
</script>

<style lang="scss" scoped>
div {
  margin-top: 10px;
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}
</style>
